import themes from "../../_data/themes.json";
import { slugify } from "../lib/slug";
import imageDigitaal from "../img/theme_pictures/digitaal.png?url";
import imageEconomie from "../img/theme_pictures/economie_handel_industrie.png?url";
import imageGemeente from "../img/theme_pictures/gemeente_algemeen.png?url";
import imageKinderen from "../img/theme_pictures/kinderen_en_jeugd.png?url";
import imageMilieu from "../img/theme_pictures/milieu_en_klimaat.png?url";
import imageMobiliteit from "../img/theme_pictures/mobiliteit.png?url";
import imageNetheid from "../img/theme_pictures/netheid_en_veiligheid.png?url";
import imageOnderwijs from "../img/theme_pictures/onderwijs.png?url";
import imageOpenRuimte from "../img/theme_pictures/open_ruimte.png?url";
import imageVrijeTijd from "../img/theme_pictures/vrije_tijd_sport_cultuur.png?url";
import imageWelzijn from "../img/theme_pictures/welzijn_en_zorg.png?url";
import imageWonen from "../img/theme_pictures/wonen.png?url";

const images = {
  digitaal: imageDigitaal,
  economie_handel_industrie: imageEconomie,
  gemeente_algemeen: imageGemeente,
  kinderen_en_jeugd: imageKinderen,
  milieu_en_klimaat: imageMilieu,
  mobiliteit: imageMobiliteit,
  netheid_en_veiligheid: imageNetheid,
  onderwijs: imageOnderwijs,
  open_ruimte: imageOpenRuimte,
  vrije_tijd_sport_cultuur: imageVrijeTijd,
  welzijn_en_zorg: imageWelzijn,
  wonen: imageWonen,
};

export default function result() {
  return {
    quoteScores: {},
    themeScores: {},
    orderedScores: [],
    orderedQuotes: [],

    init() {
      const params = new URLSearchParams(document.location.search);
      themes.forEach((theme) => {
        const value = params.get(theme.queryParam) || 0;
        this.themeScores[theme.queryParam] = parseInt(value);
      });

      this.orderedScores = Object.entries(this.themeScores)
        .sort(([, a], [, b]) => {
          // Make sure we don't favor specific themes in case of ex auquo
          if (b !== a) return b - a;
          return Math.random() > 0.5 ? 1 : -1;
        })
        .splice(0, 6);

      ["vere", "acti", "digi"].forEach((param) => {
        const value = params.get(param) || 0;
        this.quoteScores[param] = parseInt(value);
      });

      this.orderedQuotes = Object.entries(this.quoteScores).sort(
        ([, a], [, b]) => {
          // Make sure we don't favor specific quote in case of ex auquo
          if (b !== a) return b - a;
          return Math.random() > 0.5 ? 1 : -1;
        }
      );
    },

    get bestTheme() {
      return (
        themes.find((theme) => theme.queryParam === this.orderedScores[0][0]) ||
        {}
      );
    },

    get bestQuote() {
      return this.orderedQuotes[0] ? this.orderedQuotes[0][0] : "";
    },

    // Sharing
    get shareURL() {
      return `${window.location.origin}/${slugify(this.bestTheme?.key)}`;
    },

    get facebookUrl() {
      return `https://www.facebook.com/sharer/sharer.php?u=${this.shareURL}`;
    },

    get twitterUrl() {
      const text = `${this.shareURL}`;
      return `http://twitter.com/intent/tweet?text=${encodeURIComponent(text)}`;
    },

    get whatsappUrl() {
      const text = this.shareURL;
      return `whatsapp://send?text=${encodeURIComponent(text)}`;
    },

    get instagramImg() {
      return images[this.bestTheme?.key];
    },

    get instagramUrl() {
      return this.instagramImg;
    },

    async shareOnIG(event) {
      const text = `Hoe is leven in Asse voor jou? Check doemee.asse.be!`;
      event.preventDefault();
      if (this.file === null) await this.createFile();

      if (navigator.canShare && navigator.canShare({ files: [this.file] })) {
        await navigator.share({
          files: [this.file],
          text: text,
        });
      } else {
        window.open(this.instagramUrl, "_self");
      }
    },

    async shareOnFB(event) {
      if (navigator.canShare && navigator.canShare({ url: this.shareURL })) {
        event.preventDefault();
        await navigator.share({
          url: this.shareURL,
        });
      }
    },

    async createFile() {
      let response = await fetch(this.instagramUrl);
      let data = await response.blob();
      let metadata = {
        type: "image/png",
      };
      this.file = new File(
        [data],
        `${this.activeProfile?.title}.png`,
        metadata
      );
    },
  };
}
